/* eslint react/prop-types: 0 */
/* eslint no-shadow: 0 */
/* eslint arrow-body-style: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
/* eslint no-unneeded-ternary: 0 */
/* eslint react/no-array-index-key: 0 */

import { CFormInput } from "@coreui/react";
import { useEffect, useRef, useState } from "react";
import "./loading.css";
import { Spinner } from "react-bootstrap";

function SearchableDropdown({
  options,
  label,
  id,
  selectedVal,
  handleChange,
  placeholder,
  loading,
}) {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");
    handleChange(option.code);
    setIsOpen((isOpen) => !isOpen);
  };

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return options?.find((option) => option.code === selectedVal)?.name;

    return "";
  };

  const filter = (options) => {
    return options?.filter((option) => option[label]?.indexOf(query) > -1);
  };

  return (
    <div className="dropdown" onClick={toggle}>
      <div className="control">
        <div className="selected-value">
          <CFormInput
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            autocomplete="off"
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            placeholder={placeholder}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`} />
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {loading ? (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center py-5 my-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {filter(options).map((option, index) => {
              return (
                <div
                  onClick={() => selectOption(option)}
                  className={`option ${option.code === selectedVal ? "selected" : ""}`}
                  key={`${id}-${index}`}
                >
                  {option[label]}
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default SearchableDropdown;
