import useDelete from "hooks/general/useDelete";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useOrders = () => {
  const [loading, setLoading] = useState();
  const [orders, setOrders] = useState([]);
  const [containers, setContainers] = useState([]);
  const [customers1, setCustomers1] = useState([]);
  const [checked, setChecked] = useState([]);
  const [dialogReceive, setOpen] = React.useState(false);
  const [selectedContainer, setSelectedContainer] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [ordersWithStatus, setOrdersWithStatus] = useState([]);
  const { t } = useTranslation();
  const dialogtrue = () => {
    setOpen(true);
  };
  const dialogfalse = () => {
    setOpen(false);
  };
  const handleCheckChange = (event, orderID, index) => {
    setChecked((prevChecked) => {
      const newChecked = [...prevChecked];
      // const checkedOrder = filteredOrder.find((order) => order.id === orderID);
      newChecked[index] = event.target.checked;
      return newChecked;
    });
    const updatedOrdersWithStatus = [...ordersWithStatus];
    updatedOrdersWithStatus.find((order) => order.id === orderID).status = event.target.checked
      ? "received"
      : "new"; // Update status based on checkbox state
    setOrdersWithStatus(updatedOrdersWithStatus);
    console.log(updatedOrdersWithStatus);
  };

  // const { t } = useTranslation();
  const getAllOrder = async () => {
    try {
      // const token = sessionStorage.getItem("token");
      const headers = { ContentType: "application/json", Accept: "application/json" };
      const response = await fetch(`https://trust-cargo.co/anas_backend/api/orders`, {
        headers,
      });
      const data = await response.json();

      // Create an array of objects containing order IDs and statuses
      const ordersWithStatus1 = data.orders.map((order) => ({
        id: order.id,
        status: order.status,
      }));
      setOrdersWithStatus(ordersWithStatus1);
      // Set orders and checked state
      setOrders(data.orders);
      const checkedArray = new Array(ordersWithStatus.length).fill(false); // Create an array of false values with the same length as orders
      setChecked(checkedArray);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching orders:", error);
      // Handle error if needed
    }
  };

  const getContainers = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/containers");
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setContainers(data["containers"]);
  };
  const getCustomers = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/customers");
    const data = await response.json();
    setCustomers1(data.customers);
  };

  useEffect(() => {
    setLoading(true);
    getAllOrder();
    getCustomers();
    getContainers();
  }, []);

  const filteredOrder = orders.filter((order) => {
    if (
      (!selectedContainer || order.container_id === selectedContainer) &&
      (!selectedCustomer || order.customer_id === selectedCustomer)
    ) {
      return true;
    }
    if (selectedContainer === "all" || selectedCustomer === "all") {
      return true;
    }
    return false;
  });
  const handleChangeContainer = (event) => {
    const selectedValue = event.target.value;
    setSelectedContainer(selectedValue);
    const ordersWithStatus1 = filteredOrder.map((order) => ({
      id: order.id,
      status: order.status,
    }));
    setOrdersWithStatus(ordersWithStatus1);
  };
  const handleChangeCustomer = (selectedValue) => {
    // const selectedValue = event.target.value;
    setSelectedCustomer(selectedValue);
    const ordersWithStatus1 = filteredOrder.map((order) => ({
      id: order.id,
      status: order.status,
    }));
    setOrdersWithStatus(ordersWithStatus1);
  };
  console.log("ordersWithStatus");
  console.log(ordersWithStatus);

  const navigate = useNavigate();

  const editOrderStatus = async () => {
    setLoading(true);
    try {
      const ordersToSend = ordersWithStatus.map((order) => ({
        id: order.id,
        status: order.status,
      }));
      setOpen(true);
      const response = await fetch("https://trust-cargo.co/anas_backend/api/edit_order_status", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ orders: ordersToSend }),
      });

      if (!response.ok) {
        throw new Error("Failed to send orders to API");
      }
      console.log("Orders sent to API successfully");
      getAllOrder();
      setChecked([]);
    } catch (error) {
      console.error("Error sending orders to API:", error);
    }
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    for (let i = 0; i < filteredOrder.length; i += 1) {
      setChecked(filteredOrder.map(() => isChecked));
    }
    const ordersWithStatus1 = filteredOrder.map((order) => ({
      id: order.id,
      status: "received",
    }));
    setOrdersWithStatus(ordersWithStatus1);
  };

  const calculatedTotalCtn = () => {
    let totalCtn = 0;
    if (filteredOrder.length > 0) {
      filteredOrder.forEach((order) => {
        totalCtn += Number(order.total_ctn) || 0;
      });
      return totalCtn;
    }
    return null;
  };

  const calculatedTotalGW = () => {
    let totalGW = 0;
    if (filteredOrder.length > 0) {
      filteredOrder.forEach((order) => {
        totalGW += Number(order.total_gw) || 0;
      });
      return totalGW;
    }
    // eslint-disable-next-line no-useless-return
    return null;
  };
  const calculatedTotalCbm = () => {
    let totalCBM = 0;
    if (filteredOrder.length > 0) {
      filteredOrder.forEach((order) => {
        totalCBM += Number(order.total_t_cbm) || 0;
      });
      return totalCBM;
    }
    return null;
  };

  const {
    dialogdelete,
    setDialogDelete,
    openDialog,
    closeDialog,
    executeDelete,
    openDeleteSuccess,
    setOpenDeleteSuccess,
  } = useDelete("delete_order", getAllOrder);

  return {
    loading,
    setLoading,
    orders,
    setOrders,
    containers,
    setContainers,
    customers1,
    setCustomers1,
    checked,
    setChecked,
    setOpen,
    selectedContainer,
    setSelectedContainer,
    selectedCustomer,
    setSelectedCustomer,
    dialogOpen,
    setDialogOpen,
    ordersWithStatus,
    setOrdersWithStatus,
    dialogReceive,
    dialogfalse,
    dialogtrue,
    handleChangeContainer,
    handleCheckChange,
    getAllOrder,
    getContainers,
    handleChangeCustomer,
    editOrderStatus,
    handleSelectAllChange,
    calculatedTotalCbm,
    calculatedTotalCtn,
    calculatedTotalGW,
    t,
    navigate,
    filteredOrder,
    dialogdelete,
    setDialogDelete,
    openDialog,
    closeDialog,
    executeDelete,
    openDeleteSuccess,
    setOpenDeleteSuccess,
  };
};

export default useOrders;
