/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-template-curly-in-string */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { makeStyles } from "@material-ui/core/styles";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import usePosts from "hooks/posts/usePosts";
import DeleteDialog from "components/deleteDialog";

function Posts() {
  const {
    posts,
    loading,
    fetchData,
    classes,
    navigate,
    t,
    dialogdelete,
    setDialogDelete,
    openDialog,
    closeDialog,
    executeDelete,
  } = usePosts();
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Posts</SuiTypography>
            </SuiBox> */}
            <div style={{ width: "200px", margin: "20px", color: "green" }}>
              <Button
                variant="contained"
                style={{ color: "white" }}
                onClick={() => {
                  navigate("/add_post");
                }}
              >
                Add Post
              </Button>
            </div>
            {loading ? (
              <div
                style={{
                  width: "1000",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <progress className="pure-material-progress-circular" />
              </div>
            ) : (
              <Table sx={{ minWidth: 1000 }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ display: "flex" }}>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 3 }} align="center">
                        Name
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 3 }} align="start">
                        images
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 3 }} align="center">
                        {t("operations")}
                      </TableCell>
                    </Box>
                  </TableRow>
                </TableHead>
                {posts && posts.length ? (
                  <TableBody>
                    {posts.map((post) => (
                      <TableRow
                        key={post.id}
                        style={{ marginLeft: "20px" }}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },
                          display: "flex",
                        }}
                      >
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 3 }} align="center">
                            <div dangerouslySetInnerHTML={{ __html: post.body }} />
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 3 }} align="center">
                            <ImageList
                              className={classes.imageList}
                              cols={3}
                              style={{ marginTop: "10px" }}
                            >
                              {post.images.map((item) => (
                                <ImageListItem key={item.image}>
                                  <img
                                    src={item.image}
                                    style={{ width: "150px", height: "100px" }}
                                  />
                                </ImageListItem>
                              ))}
                            </ImageList>
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell
                            sx={{ width: window.innerWidth / 3 }}
                            align="center"
                            style={{ flexDirection: "row" }}
                          >
                            <SuiTypography
                              component="a"
                              href="#"
                              variant="caption"
                              color="secondary"
                              fontWeight="medium"
                              onClick={() => {
                                navigate(`/view_post/${post.id}`);
                              }}
                            >
                              {t("View")}
                            </SuiTypography>
                            <SuiTypography
                              component="a"
                              variant="caption"
                              color="primary"
                              fontWeight="medium"
                              margin="15px"
                              onClick={() => {
                                openDialog(post.id);
                              }}
                            >
                              {t("delete")}
                            </SuiTypography>
                          </TableCell>
                        </Box>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 2 }} align="center">
                        There is no data to display
                      </TableCell>
                    </Box>
                  </TableBody>
                )}
              </Table>
            )}
          </Card>
          <DeleteDialog
            dialogdelete={dialogdelete}
            closeDialog={closeDialog}
            deleteItem={executeDelete}
          />
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Posts;
