/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable dot-notation */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import {
  CNavbar,
  CContainer,
  CNavbarBrand,
  CButton,
  CSpinner,
  CCard,
  CCardImage,
  CCardBody,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./loading.css";

function HomeFive() {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetch("https://trust-cargo.co/anas_backend/api/posts")
      .then((response) => response.json())
      .then((data) => {
        setPosts(data.posts);
        setLoading(false);
      });
  }, []);

  const slideSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    fade: true,
    nextArrow: <FaChevronCircleRight size={35} className="text-primary cursor-pointer" />,
    prevArrow: <FaChevronCircleLeft size={35} className="text-primary cursor-pointer" />,
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-column">
      <CNavbar colorScheme="dark" className="bg-dark shadow py-3">
        <CContainer fluid>
          <CNavbarBrand className="fw-bold text-white fs-4">Welcome To Anas Jweiles</CNavbarBrand>
          <CButton
            color="primary"
            className="px-4 py-2"
            onClick={() => navigate("/authentication/sign-in")}
          >
            Login
          </CButton>
        </CContainer>
      </CNavbar>

      <CContainer className="py-5 flex-grow-1">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center min-vh-50">
            <CSpinner color="primary" size="lg" />
          </div>
        ) : posts.length > 0 ? (
          <CRow className="g-4 justify-content-center">
            {posts.map((post) => (
              <CCol key={post.id} md={6} lg={4} className="d-flex">
                <CCard
                  className="shadow-lg border-0 rounded overflow-hidden w-100"
                  onClick={() => navigate(`/single-post/${post.id}`)}
                >
                  <Slider {...slideSettings} className="rounded-top">
                    {post.images.map((imageData, index) => (
                      <CCardImage
                        key={index}
                        src={imageData.image}
                        alt="Post"
                        className="w-100 rounded-top"
                      />
                    ))}
                  </Slider>
                  <CCardBody className="p-4">
                    <CCardTitle
                      className="text-dark fw-bold cursor-pointer fs-5"
                      onClick={() => navigate(`/single-post/${post.id}`)}
                      dangerouslySetInnerHTML={{ __html: post.body }}
                    />
                    <CCardText className="text-muted mt-2">Click to read more...</CCardText>
                  </CCardBody>
                </CCard>
              </CCol>
            ))}
          </CRow>
        ) : (
          <p className="text-center text-muted fw-bold fs-5">No posts available</p>
        )}
      </CContainer>
    </div>
  );
}

export default HomeFive;
